
import './App.css';

function App() {
    return (
        <div className="main-container">
            <div style={{display:'flex', flexDirection:"row", justifyContent:"flex-start", width:'100%'}}>
                <img src={'/q8_logo.png'} alt={''} className={'logo'} />
            </div>
            <iframe id="MemzoInt" src="https://memzo.ai/h/7i8s02mb/11656/16675"
                    allow="camera *"
                    style={{border:'none',width:'100vw',height:'85vh',overflowY: 'hidden',overflow:'hidden'}}
                    title="Get Your Photos">
            </iframe>

        </div>
    );
}

export default App;
